import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { TextSm } from '@system'

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
  },
  link: {
    color: theme.palette.text.secondary,
    display: 'block',
  },
}))

const ContactCard = props => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <Box marginBottom="24px">
        <TextSm className={classes.name}>{props.blok.name}</TextSm>
        {!!props.blok.link.length && (
          <TextSm className={classes.link}>
            {renderBloks(props.blok.link)}
          </TextSm>
        )}
      </Box>
    </SbEditable>
  )
}

export default ContactCard
